import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Unstable_Grid2';

export default function MenuCustom() {
    return (
      <Grid container spacing={2} style={{ justifyContent: "space-evenly"}}>

        <Grid>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button>QUERO SER DEV</Button>
          </ButtonGroup>
        </Grid>

        <Grid>
          <Button>Sobre</Button>
        </Grid>

        <Grid>
          <Button>Pogramação</Button>
        </Grid>

        <Grid>
          <Button>Mentoria</Button>
        </Grid>


      </Grid>
    );
}