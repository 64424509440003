
import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import Home from './pages/home';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';


const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
]);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
      <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <RouterProvider router={router} />
      </ThemeProvider>
  );
}

export default App;
