import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Unstable_Grid2';
import LoginIcon from '@mui/icons-material/Login';

export default function MenuCustom() {
    return (
      <Grid container spacing={2} style={{ justifyContent: "space-evenly"}}>
        <Grid>
          <Button variant="contained" endIcon={<LoginIcon />}>
            Login
          </Button>
        </Grid>
      </Grid>
    );
}