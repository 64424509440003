import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import MenuCustom from '../menu_custom/menu_custom';
import LogoFloat from "../logo/logo";
import Login from '../login/login';
import Hidden from '@mui/material/Hidden';


export default function MenuTop() {
    return (
      <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} style={{ justifyContent: "space-evenly"}}>
              <Grid xs={2}>
                  <LogoFloat />
              </Grid>
              <Hidden only={'xs'}>
                <Grid xs={8} >

                   <MenuCustom />
                </Grid>
              </Hidden>
            <Grid xs={2} style={{ backgroundColor: ''}}>
                  <Login />
              </Grid>
          </Grid>
      </Box>
    );
}