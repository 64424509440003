import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MenuTop from '../components/grip_menu_top/menu_top'
import Banner from '../icons/banner/man-analysing-binary-code-on-virtual-screen.webp';
import TypedPin from '../components/typed/typed_pin';

export default function Home() {
 return (
     <React.Fragment>
       <Box sx={{ height: '100vh', minHeight: '500px' }} style={{ backgroundImage: `url(${Banner})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} >
         <Container maxWidth="xl">
            <MenuTop />
           <div style={{ height: '10vh', justifyContent: 'center'}}>
             <TypedPin />
           </div>
         </Container>
       </Box>
       <Box sx={{ height: '100vh' }} >
         <Container maxWidth="xl">
         </Container>
       </Box>
     </React.Fragment>
 );
}