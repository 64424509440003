import Typed from "typed.js";
import { useEffect, useRef } from "react";

export default function TypedPin() {

 const el = useRef<HTMLSpanElement>(null);
 const textDinamic = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    // @ts-ignore
    const typed = new Typed(el.current, {
      strings: ["Lorem ipsum dolor sit amet, elit."], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 300,
      typeSpeed: 10,
      backSpeed: 100,
      backDelay: 100,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  useEffect(() => {
    // @ts-ignore
    const typed = new Typed(textDinamic.current, {
      strings: [" Pellentesque et pretium magna. Vestibulum at molestie felis. Integer sit amet libero volutpat, lobortis ante id, " +
      "consequat dui. Morbi efficitur blandit magna. Phasellus iaculis tristique purus auctor volutpat. " +
      "tincidunt a ultricies eu, tempus sed nibh. Duis quis dignissim est. Nam rhoncus tempor augue, vel efficitur lacus. " +
      "In vitae congue erat. Curabitur non orci tincidunt, fermentum nisl quis, pellentesque erat."], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 300,
      typeSpeed: 1,
      backSpeed: 100,
      backDelay: 10000,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div style={{ backgroundColor: '', maxWidth: '50%', paddingTop: '20vh'}}>
      <h1>
      {/* Element to display typing strings */}
      <span style={{  }} ref={el}></span>
      </h1>
      <span style={{ }} ref={textDinamic}></span>
    </div>
  );
}

